import React from "react";

function DemoPlayer() {
	return (
		<React.Fragment>
			<iframe
				title="spotify"
				style={{ borderRadius: "12px" }}
				src="https://open.spotify.com/embed/artist/4FkGFx7r2YKwf7cEzMGmrx?utm_source=generator"
				width="100%"
				height="230"
				frameBorder="0"
				allowfullscreen=""
				allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
			></iframe>
		</React.Fragment>
	);
}

export default DemoPlayer;
