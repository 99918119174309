import React from "react";
import Widget from "../events/SongKickWidget";

function HardShortPage() {
	return (
		<React.Fragment>
			<div className="container-fluid-mdapp-bg">
				<div className="row mx-2 sectionPeach">
					<div className="card-fluid my-3 p-lg-2">
						<div className="card-title">
							<h1 className="text-center mb-3 font-title colorWhite font-weight-big">
								Upcoming shows...
							</h1>
						</div>
						<div className="card-body font-body">
							<Widget />
							<h4 className="pt-3 colorWhite font-body">
								<a href="/events" className="colorRed">
									Click Here
								</a>{" "}
								for more shows and tour dates info.
							</h4>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default HardShortPage;
