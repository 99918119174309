import React from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import Link from "react-bootstrap/NavLink";
import { NavLink } from "react-router-dom";

function NavBar() {
	return (
		<React.Fragment>
			<Navbar
				sticky="top"
				collapseOnSelect
				expand="lg"
				bg="black"
				variant="dark"
			>
				<Container>
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					<Navbar.Collapse id="responsive-navbar-nav">
						<Nav className="w-100 justify-content-center">
							<Nav.Item>
								<NavLink
									className="font-nav"
									style={({ isActive }) => {
										return isActive ? { color: "whitesmoke" } : {};
									}}
									eventKey="1"
									as={Link}
									to="/"
								>
									<h1 className="fw-bolder font-size">Home</h1>
								</NavLink>
							</Nav.Item>
							<Nav.Item>
								<NavLink
									className="font-nav"
									style={({ isActive }) => {
										return isActive ? { color: "whitesmoke" } : {};
									}}
									eventKey="2"
									as={Link}
									to="/bio"
								>
									<h1 className="fw-bolder font-size">Bio</h1>
								</NavLink>
							</Nav.Item>
							<Nav.Item>
								<NavLink
									className="font-nav"
									style={({ isActive }) => {
										return isActive ? { color: "whitesmoke" } : {};
									}}
									eventKey="3"
									as={Link}
									to="/merch"
								>
									<h1 className="fw-bolder font-size">Merch</h1>
								</NavLink>
							</Nav.Item>
							<Nav.Item>
								<NavLink
									className="font-nav"
									style={({ isActive }) => {
										return isActive ? { color: "whitesmoke" } : {};
									}}
									eventKey="4"
									as={Link}
									to="/events"
								>
									<h1 className="fw-bolder font-size">Events</h1>
								</NavLink>
							</Nav.Item>
							<Nav.Item>
								<NavLink
									className="font-nav"
									style={({ isActive }) => {
										return isActive ? { color: "whitesmoke" } : {};
									}}
									eventKey="5"
									as={Link}
									to="/media"
								>
									<h1 className="fw-bolder font-size">Media</h1>
								</NavLink>
							</Nav.Item>
							<Nav.Item>
								{/* <NavLink
									className="font-nav"
									style={({ isActive }) => {
										return isActive ? { color: "whitesmoke" } : {};
									}}
									eventKey="6"
									as={Link}
									to="/socials"
								>
									<h1 className="fw-bolder font-size">Socials</h1>
								</NavLink> */}
							</Nav.Item>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</React.Fragment>
	);
}

export default NavBar;
