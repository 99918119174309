import React from "react";
import HardCodeVideo from "./HardCodeVideo";
import HardCodePhoto from "./HardCodePhoto";
import DemoPlayer from "../musicPhotos/DemoPlayer";

function HardCodeMedia() {
	return (
		<React.Fragment>
			<div className="container-fluid colorWhite app-bg">
				<div id="DEMOPLAYER" className="row mt-5">
					<div className="col-sm-12 col-lg-10 offset-lg-1 mt-2">
						<DemoPlayer />
					</div>
				</div>
				<div id="PAGINATIONS" className="row">
					<div id="VIDEOSIDE" className="col-lg-6 col-sm-12 px-3">
						<h1 className="row justify-content-center font-title mb-3">
							VIDEOS
						</h1>
						<div className="row justify-content-center">
							<div className="col mb-4 text-center">
								<div className="row mb-1" id="cloneContainer">
									<HardCodeVideo />
								</div>
							</div>
						</div>
					</div>
					<div id="PHOTOSIDE" className="col-lg-6 col-sm-12 px-3">
						<h1 className="row justify-content-center font-title mb-3">
							PHOTOS
						</h1>
						<div className="row justify-content-center">
							<div className="col mb-4 text-center">
								<div className="row mb-1" id="cloneContainer">
									<HardCodePhoto />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default HardCodeMedia;
