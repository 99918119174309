import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import HomePage from "./components/homePage/HomePage";
import Bio from "./components/bio/Bio";
import Events from "./components/events/Events";
import Merch from "./components/merch/Merch";
import HardCodeMedia from "./components/hardCodedMedia/HardCodeMedia";
import Socials from "./components/socialMedia/Socials";
import React from "react";
import NavBar from "./components/NavBar";
import PageBanner from "./components/PageBanner";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
	return (
		<React.Fragment>
			<div className="container-fluid App app-bg">
				<header>
					<PageBanner />
				</header>
				<Router>
					<NavBar />
					<div className="row">
						<div className="col-1 d-none d-xxl-block empty" />
						<div className="col">
							<Routes>
								<Route path="/" element={<HomePage />}></Route>
								<Route path="/bio" element={<Bio />}></Route>
								<Route path="/events" element={<Events />}></Route>
								<Route path="/merch" element={<Merch />}></Route>
								<Route path="/media" element={<HardCodeMedia />}></Route>
								<Route path="/socials" element={<Socials />}></Route>
								{/* <Route path="/login" element={<AdminLogin />}></Route>
					<Route path="/adminHome" element={<AdminHome />}></Route> */}
							</Routes>
						</div>
						<div className="col-1 d-none d-xxl-block empty" />
					</div>
				</Router>
				<footer>
					<Footer />
				</footer>
			</div>
		</React.Fragment>
	);
}

export default App;
