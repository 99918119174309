import React from "react";
import bannerPic from "../images/Darnell_Cole_Splash_Screen.png";

function Banner() {
	return (
		<React.Fragment>
			<div className="banner">
				<img src={bannerPic} alt="" className="w-100 my-5" />
			</div>
		</React.Fragment>
	);
}

export default Banner;
