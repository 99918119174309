import React from "react";

function BaseBioCol() {
	return (
		<React.Fragment>
			<div className="container-fluid">
				{/* <h1 className="text-center colorOrange font-title pt-4">
					Darnell Cole
				</h1> */}
				{/* <h1 className="text-center">
					<span class="fontawesome-star star"></span>{" "}
					<span className="tilt font-tilt">Darnell Cole</span>{" "}
					<span class="fontawesome-star star"></span>
				</h1> */}
				{/* <div className="vintage__container">
					<h1 className="vintage vintage__top">Darnell Cole</h1>
					<h1 className="vintage vintage__bot">Darnell Cole</h1>
				</div> */}

				<div className="card-fluid my-3 p-lg-2">
					<div className="row align-items-center sectionPeach">
						<div className="col-md-3 pt-3 p-lg-3">
							<img
								className="img-fluid rounded-circle shadow bdStyle baseBio-pic-width"
								src="https://live.staticflickr.com/65535/52420534867_6e80f17598_w.jpg"
								alt="Darnell Cole"
							/>
						</div>
						<div className="col-md-9">
							<div className="card-body">
								<h1 className="card-title text-center">
									<span class="fontawesome-star star"></span>{" "}
									<span className="font-tilt">Darnell Cole</span>{" "}
									<span class="fontawesome-star star"></span>
								</h1>
								<h3 className="card-text mt-2 fw-bolder font-body colorWhite">
									Darnell "Big D" Cole is an American Rock and Soul artist. Born
									in the blues, the soulful music is centered around his
									electric and captivating guitar playing, often taking you on a
									journey, emotionally and spiritually.
								</h3>
								<br />
								<h3 className="card-text mb-2 fw-bolder font-body colorWhite">
									For 9 years, he fronted the international rock band Porcelain
									Hill, performing small local backyard gigs, to sharing stages
									with the likes of Iron Butterfly and Walter Trout. This
									unsigned, independent Blues King is on a mission to spread his
									gospel of rock, soul, and positivity.
								</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default BaseBioCol;
