import React from "react";

function BaseMusicCol() {
	return (
		<React.Fragment>
			<div className="">
				<div className="row iframe-container mb-4">
					<iframe
						className="col m-lg-3"
						title="video 1"
						frameborder="0"
						height={288}
						width="50%"
						scrolling="no"
						src="https://www.youtube.com/embed/9EhCN9UYuZY?list=OLAK5uy_mRdAiZBhL8OtVRwRKz8nqYqOFhmG0Xqqk"
					></iframe>
					<iframe
						className="col m-lg-3 d-none d-lg-block"
						title="video 2"
						frameborder="0"
						height={288}
						width="50%"
						scrolling="no"
						src="https://www.youtube.com/embed/sIi8bPQfUhk?list=OLAK5uy_kWr26Vcg_R77TtWbxA-cs4w7RvFr2s0Ug"
					></iframe>
				</div>
				<div className="row iframe-container">
					<iframe
						className="col m-lg-3 d-none d-lg-block"
						title="video 3"
						frameborder="0"
						height={288}
						width="50%"
						scrolling="no"
						src="https://www.youtube.com/embed/WhABWaqluiE?list=OLAK5uy_lwWy5320uYuwqAZOXKszDvEQGn3dr_p3Q"
					></iframe>
					<iframe
						className="col m-lg-3"
						title="video 4"
						frameborder="0"
						height={288}
						width="50%"
						scrolling="no"
						src="https://www.youtube.com/embed/ek5JLg4p2Lc"
					></iframe>
				</div>
			</div>
		</React.Fragment>
	);
}

export default BaseMusicCol;
