import React from "react";
import HardShortPage from "../hardCodedMedia/HardShortPage";
import BaseMusicCol from "../musicPhotos/BaseMusicCol";
import BaseTestimonyCol from "../bio/BaseTestimonyCol";
import BaseBioCol from "../bio/BaseBioCol";
import flyer from "../../images/TourFlyer2024.jpg";

function HomePage() {
	return (
		<React.Fragment>
			<div className="container-fluid font-body app-bg">
				<div id="LOGO-TESTIMONIES" className="row">
					<div className="col sectionBlack px-4">
						<div className="row mb-2">
							<div className="col my-5 text-center">
								<div className="bio-pic my-5">
									{/* <img
										src="https://live.staticflickr.com/65535/52501494233_3e5660e9b4_k.jpg"
										alt=""
										width="100%"
										className="bandName"
									/> */}
								</div>
							</div>
						</div>
						{/* <BaseTestimonyCol /> */}
						<div className="row mt-5 pt-2">
							{/* <h1 className="font-title text-center">
								<span class="fontawesome-star star"></span>{" "}
								<span className="tilt font-Blue">New Official Music Video</span>{" "}
								<span class="fontawesome-star star"></span>
							</h1> */}
							{/* <h2 className="text-center font-body colorWhite">On My Own</h2> */}
						</div>
						<div className="row mb-5">
							<div className="col-1 d-none d-lg-block empty"></div>
							<div className="col">
								<img
									id="tourFlyer"
									src={flyer}
									alt="Blank Ticket"
									className="tourFlyer mb-5"
								/>
								<div className="video-container">
									<iframe
										className="officialVideo"
										title="officialVideo"
										frameborder="0"
										scrolling="no"
										src="https://www.youtube.com/embed/iQtHECBP8Ac?si=SKO9pxA7xUNkQXfx"
									></iframe>
								</div>
							</div>
							<div className="col-1 d-none d-lg-block empty"></div>
						</div>
					</div>
				</div>

				<div id="BIOSMALL" className="row">
					<div className="col woodTif px-4">
						<BaseBioCol />
					</div>
				</div>

				<div id="MUSICVIDEOS" className="row woodOrange app-bg">
					<div className="col p-4">
						<div className="row">
							<BaseMusicCol />
						</div>
					</div>
				</div>

				<div id="EVENTSTABLE" className="row mb-5">
					<div className="col p-4 woodTif">
						<HardShortPage />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default HomePage;
