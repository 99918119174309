import React from "react";
import shirtOne from "../../images/shirt1.jpg";
import shirtTwo from "../../images/shirt2.jpg";
import shirtThree from "../../images/shirt3.jpg";
import shirtFour from "../../images/shirt4.jpg";
import shirtFive from "../../images/shirt5.jpg";

function Banner() {
	return (
		<React.Fragment>
			{/* <img src={shirtOne} alt="Shirt One" className="w-100" /> */}
			<div className="row row-cols-1 row-cols-lg-3 g-4 my-5 justify-content-center">
				{/* <div className="col woodTif p-4 mx-3">
					<div className="card h-100 woodTif rounded-4 p-2">
						<img
							src={shirtOne}
							alt="Shirt One"
							className="w-100 sectionPeach card-img-top"
						/>
						<div className="card-body sectionPeach">
							<h5 className="card-title font-title colorWhite">Print One</h5>
							<p className="card-text font-body colorWhite">
								Available as a T-Shirt or Hoodie. Mens and women options with
								multiple colors available.
								<br />
								<a
									href="https://skullprint.co.uk/shop/ols/products/darnell-cole-vibe-design-t-shirt-or-hoodie"
									className="colorRed"
									target="_blank"
								>
									Click Here
								</a>{" "}
								to puchase.
							</p>
						</div>
					</div>
				</div> */}
				<div className="col woodTif p-4 mx-3">
					<div className="card h-100 rounded-4 p-2">
						<img
							src={shirtOne}
							alt="Shirt One"
							className="w-100 sectionPeach card-img-top"
						/>
						<div className="card-body">
							<h5 className="card-title font-title">Vibe Design</h5>
							<p className="card-text font-body">
								Available as a T-Shirt or Hoodie. Mens and women options with
								multiple colors available.
								<br />
								<br />
								<a
									href="https://skullprint.co.uk/shop/ols/products/darnell-cole-vibe-design-t-shirt-or-hoodie"
									className="colorRed"
									target="_blank"
									rel="noreferrer"
								>
									Click Here
								</a>{" "}
								to puchase.
							</p>
						</div>
					</div>
				</div>

				<div className="col woodTif p-4 mx-3">
					<div className="card h-100 rounded-4 p-2">
						<img
							src={shirtTwo}
							alt="Shirt Two"
							className="w-100 sectionPeach card-img-top"
						/>
						<div className="card-body">
							<h5 className="card-title font-title">
								Soulful Blues Rock Design
							</h5>
							<p className="card-text font-body">
								Available as a T-Shirt or Hoodie. Mens and women options with
								multiple colors available.
								<br />
								<br />
								<a
									href="https://skullprint.co.uk/shop/ols/products/darnell-cole-c-soulful-blues-rock-design-t-shirt-or-hoodie"
									className="colorRed"
									target="_blank"
									rel="noreferrer"
								>
									Click Here
								</a>{" "}
								to puchase.
							</p>
						</div>
					</div>
				</div>
				<div className="col woodTif p-4 mx-3">
					<div className="card h-100 rounded-4 p-2">
						<img
							src={shirtThree}
							alt="Shirt Three"
							className="w-100 sectionPeach card-img-top"
						/>
						<div className="card-body">
							<h5 className="card-title font-title">Guitar Design</h5>
							<p className="card-text font-body">
								Available as a T-Shirt or Hoodie. Mens and women options with
								multiple colors available.
								<br />
								<br />
								<a
									href="https://skullprint.co.uk/shop/ols/products/darnell-cole-c-guitar-design-t-shirt-or-hoodie"
									className="colorRed"
									target="_blank"
									rel="noreferrer"
								>
									Click Here
								</a>{" "}
								to puchase.
							</p>
						</div>
					</div>
				</div>
				<div className="col woodTif p-4 mx-3">
					<div className="card h-100 rounded-4 p-2">
						<img
							src={shirtFour}
							alt="Shirt Four"
							className="w-100 sectionPeach card-img-top"
						/>
						<div className="card-body">
							<h5 className="card-title font-title">Night Sky Design</h5>
							<p className="card-text font-body">
								Available as a T-Shirt or Hoodie. Mens and women options with
								multiple colors available.
								<br />
								<br />
								<a
									href="https://skullprint.co.uk/shop/ols/products/darnell-cole-c-night-sky-t-shirt-or-hoodie"
									className="colorRed"
									target="_blank"
									rel="noreferrer"
								>
									Click Here
								</a>{" "}
								to puchase.
							</p>
						</div>
					</div>
				</div>
				<div className="col woodTif p-4 mx-3">
					<div className="card h-100 rounded-4 p-2">
						<img
							src={shirtFive}
							alt="Shirt Five"
							className="w-100 sectionPeach card-img-top"
						/>
						<div className="card-body">
							<h5 className="card-title font-title">Airship Design</h5>
							<p className="card-text font-body">
								Available as a T-Shirt or Hoodie. Mens and women options with
								multiple colors available.
								<br />
								<br />
								<a
									href="https://skullprint.co.uk/shop/ols/products/darnell-cole-c-airship-design-t-shirt-or-hoodie"
									className="colorRed"
									target="_blank"
									rel="noreferrer"
								>
									Click Here
								</a>{" "}
								to puchase.
							</p>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default Banner;
