import React from "react";

function Socials() {
	return (
		<React.Fragment>
			<div className="container-fluid-md text-style app-bg">
				<div className="row text-left">
					<div className="col">
						<iframe
							title="instagram"
							style={{ borderRadius: "12px" }}
							src="https://www.instagram.com/p/ClZEX8cJFr0/"
							width="100%"
							height="230"
							frameBorder="0"
							allowfullscreen=""
							allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
						></iframe>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default Socials;
