import React from "react";

function Bio() {
	return (
		<React.Fragment>
			<div id="FIRSTCARD" className="container-fluid woodTif my-5 px-4">
				<div className="row text-left">
					<div className="col">
						<div className="card-fluid my-3 p-lg-2">
							<div className="row align-items-center sectionPeach">
								<div className="col-md-5 col-lg-3 text-center pt-3 p-lg-3">
									<img
										className="img-fluid rounded-circle shadow bdStyle bio-pic-width"
										src="https://live.staticflickr.com/65535/52421362274_06758cac67.jpg"
										alt="Darnell Cole"
									/>
								</div>
								<div className="col-md-7 col-lg-9">
									<div className="card-body">
										<h1 className="card-title text-center">
											<span class="fontawesome-star star"></span>{" "}
											<span className="tilt font-tilt">Darnell Cole</span>{" "}
											<span class="fontawesome-star star"></span>
										</h1>
										<h3 className="card-text mt-2 fw-bolder font-body colorWhite">
											Darnell "Big D" Cole is an Independent Rock/Americana
											artist. As a Blues and Soul musician, his art is centered
											around his captivating electric guitar, taking one on a
											journey emotionally and spiritually.
										</h3>
										<br />
										<h3 className="card-text mb-2 fw-bolder font-body colorWhite">
											Big D was born in the summer of 1990, in Pasadena,
											California, to biological parents Marty Murphy and Cemone
											Curtis. At 4 months old he was adopted by Dennis and Janet
											Cole where everything changed. His parents performed in a
											top 40 cover band which allowed music/Instruments to
											always be accessible. It was commonplace to see Darnell
											roaming the house with nothing but a diaper and a guitar.
											At 10 years old, Darnell began playing bass and started
											experimenting with original music. At age 15, he picked up
											a guitar and leanrned a few chords and a year later wrote
											his first song.
										</h3>
										<br />
										<h3 className="card-text mb-2 fw-bolder font-body colorWhite">
											From 16-18, he would busk outside his local grocery
											store/coffee shop, sometimes for 12 hours, 5 days a week,
											learning new songs, and perfecting his craft. This became
											his main source of income and one of the biggest
											contributing factors on his path towards stardom. At 18,
											he became an EMT, providing the experience of caring for
											others during their hardest times. Being a frontline
											responder helped shape his outlook, and his passion for
											connecting with people. "Music heals the wounds medicine
											can't fix", is a statement often heard uttered by Big D
											when asked why he does music.
										</h3>
										<br />
										<h3 className="card-text mb-2 fw-bolder font-body colorWhite">
											In 2011, he released his first solo, acoustic album:
											"Loving Antics", followed up by "On the low" and "For the
											First Time". A young Darnell was ambitious for bigger and
											better things, eventually leading to the start of blues
											rock band: Porcelain Hill.
										</h3>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="SECONDCARD" className="container-fluid my-5 px-4">
				<div className="row text-left">
					<div className="col">
						<div className="card-fluid my-3 p-lg-2">
							<div className="row align-items-center">
								<div className="col">
									<div className="card-body">
										<h3 className="card-text mb-2 fw-bolder font-body colorWhite">
											After a single jam with drummer Sonny Campos, the
											Porcelain Hill chapter began, first revising and
											re-approaching old songs from Darnell's existing catalog,
											to writing fresh, new material. The duo, fronted by Big D,
											would go on to win several Battle of the Bands, as well as
											be signed to small indie label, Wright Records. Shortly
											after their first album, Rock n Rolla, the duo would
											decide to ditch the label and become an Independent trio,
											adding Jacob Lucero to play bass on the project.
										</h3>
										<br />
										<h3 className="card-text mb-2 fw-bolder font-body colorWhite">
											Darnell, at the helm of Porcelain Hill, would go on to
											tour 9 different countries, all booked and setup by
											himself, as well as write and release 2 more albums, and
											an EP. During this period he would really cut his teeth,
											opening up for/sharing the stage with more established
											artists such as Missing Persons, Iron Butterfly, Sister
											Sledge, Dru Hill, John Illsley, The Wailers, Ana Popovic,
											Walter Trout, Robert Jon & The Wreck, Bernie Marsden and
											more! During this time, Big D became a Vintage Guitars
											Endorser, receiving the honor of having several
											signature/custom guitars with the company, as well as
											being featured on several of their publications.
										</h3>
										<br />
										<h3 className="card-text mb-2 fw-bolder font-body colorWhite">
											In 2019, he booked a 6 month UK tour for Porcelain Hill,
											and led the band to victory in several Battle of the Bands
											despite having bass player lineup changes. This was also
											the year he really started to breakout individually,
											earning a spot performing at the Electric Picnic festival
											in Ireland, alongside such artists as Amy Montgomery, The
											Strokes, Billie Eilish, and The 1975 to name a few.
										</h3>
										<br />
										<h3 className="card-text mb-2 fw-bolder font-body colorWhite">
											Unfortunately, the pandemic in 2020 put a pause on the
											growth and momentum gained in 2019, but also brought a
											period of personal growth for Big D as a songwriter,
											musicain, and performer. In 2021, he wrote and released
											one more album and one more EP with Porcelian Hill, and
											toured with the new material until the band disbanded in
											summer of 2022.
										</h3>
										<img
											className="img-fluid rounded-5 shadow bdStyle w-100 bioSticky mt-4"
											src="https://live.staticflickr.com/65535/52421513055_6b099948a0_b.jpg"
											alt="Darnell Cole"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="THIRDCARD" className="container-fluid woodTif my-5 px-4">
				<div className="row text-left">
					<div className="col">
						<div className="card-fluid my-3 p-lg-2">
							<div className="row align-items-center sectionPeach">
								<div className="col-md-7 col-lg-9">
									<div className="card-body">
										<h3 className="card-text mb-2 fw-bolder font-body colorWhite">
											Sadly, Porcelian Hill disbanded with only 7 days before a
											scheduled UK tour, but Darnell recognized the chance to
											seize an opportunity. Within that week, he recorded an EP,
											rebranded, and put together a band for his solo project:
											"Darnell Cole & the Vibe". The new project went on to tour
											the UK and Ireland, with the debut show being a welcomed
											return to the Electric Picnic festival.
										</h3>
										<br />
										<h3 className="card-text mb-2 fw-bolder font-body colorWhite">
											Recently, Darnell has just released a debut album, "A Man
											of Soul". You can listen to the album by picking up a
											physical copy available at LIVE shows or via download,
											exclusively from Bandcamp.com! The album will be released
											song by song as singles throughout the year on all major
											streaming platforms. He will be taking this album across
											the pond and sharing the music on his upcoming "Soul
											Funkin' Wicked Tour"! The tour will span two months across
											the UK and Ireland, which will include performances at
											some noteable festivals; PAX in The Park in Sheffield
											England, and once again at the Electric Picnic in Ireland!
										</h3>
										<br />
										<h3 className="card-text mb-2 fw-bolder font-body colorWhite">
											The Sky is the limit for this Rocker out of Southern
											California, check back here for more updates to his story.
										</h3>
									</div>
								</div>
								<div className="col-md-5 col-lg-3 text-center pt-3 p-lg-3">
									<img
										className="img-fluid rounded-5 shadow bdStyle bio-pic-width"
										src="https://live.staticflickr.com/65535/52421352774_4ded0a1d5f.jpg"
										alt="Darnell Cole"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default Bio;
