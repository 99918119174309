import React from "react";
import {
	faApple,
	faFacebook,
	faPatreon,
	faInstagram,
	faSpotify,
	faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelopeOpenText } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import { Formik, Field, Form } from "formik";

function Footer() {
	return (
		<React.Fragment>
			<div className="container-fluid">
				<div className="row d-flex py-4 border-top border-white align-items-center justify-content-center">
					{/* <div id="NAV-LINKS-FOOTER" className="col col-lg-4 mt-4">
						<ul>
							<li className="item">
								<a
									className="decorations-on-link text-uppercase fw-bolder colorRed"
									href="/home"
								>
									Home
								</a>
							</li>
							<li className="item">
								<a
									className="decorations-on-link text-uppercase fw-bolder colorRed"
									href="/bio"
								>
									Bio
								</a>
							</li>
							<li className="item">
								<a
									className="decorations-on-link text-uppercase fw-bolder colorRed"
									href="https://skullprint.co.uk/shop/ols/products/darnell-cole-vibe-design-t-shirt-or-hoodie"
									target="_blank"
									rel="noreferrer"
								>
									Merch
								</a>
							</li>
							<li className="item">
								<a
									className="decorations-on-link text-uppercase fw-bolder colorRed"
									href="/events"
								>
									Events
								</a>
							</li>
							<li className="item">
								<a
									className="decorations-on-link text-uppercase fw-bolder colorRed"
									href="/musicphotos"
								>
									Media
								</a>
							</li>
							<li className="item">
								<a
									className="decorations-on-link text-uppercase fw-bolder colorRed d-none"
									href="/socials"
								>
									Socials
								</a>
							</li>
						</ul>
					</div> */}

					<div id="ICON-LINKS" className="col col-lg-4">
						<div>
							<a
								href="https://www.instagram.com/darnellcolemusic/"
								target="_blank"
								rel="noreferrer"
								className="decorations-on-link colorRed ms-2"
							>
								<FontAwesomeIcon icon={faInstagram} fontSize={30} />
							</a>
							<a
								href="https://www.facebook.com/darnell.cole"
								target="_blank"
								rel="noreferrer"
								className="decorations-on-link colorRed ms-2"
							>
								<FontAwesomeIcon icon={faFacebook} fontSize={30} />
							</a>
							<a
								href="https://www.youtube.com/channel/UCO2e1sIqJjJsLk5MtCXd6IA"
								target="_blank"
								rel="noreferrer"
								className="decorations-on-link colorRed ms-2"
							>
								<FontAwesomeIcon icon={faYoutube} fontSize={30} />
							</a>
							{/* <br /> */}
							<a
								href="https://open.spotify.com/artist/4FkGFx7r2YKwf7cEzMGmrx?si=P76u5iwHQTWpka6NbBvKHg"
								target="_blank"
								rel="noreferrer"
								className="decorations-on-link colorRed ms-2"
							>
								<FontAwesomeIcon icon={faSpotify} fontSize={30} />
							</a>
							<a
								href="https://music.apple.com/us/artist/darnell-cole/1266559264"
								target="_blank"
								rel="noreferrer"
								className="decorations-on-link colorRed ms-2"
							>
								<FontAwesomeIcon icon={faApple} fontSize={30} />
							</a>
							<a
								href="https://www.patreon.com/DarnellColeMusic"
								target="_blank"
								rel="noreferrer"
								className="decorations-on-link colorRed ms-2"
							>
								<FontAwesomeIcon icon={faPatreon} fontSize={30} />
							</a>
						</div>
					</div>

					<div id="CONTACT-INFO" className="col-lg-4">
						<div className="email-logo d-none">
							<FontAwesomeIcon icon={faEnvelopeOpenText} fontSize={30} />
						</div>
						<h3 className="font-body text-center mt-3 text-uppercase fw-bolder colorRed">
							CONTACT INFO:
						</h3>
						<h4 className="font-body text-center">
							<a
								href="mailto: Dneezycole@gmail.com "
								className="decorations-on-link text-decoration-underline colorRed"
							>
								Dneezycole@gmail.com
							</a>
						</h4>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default Footer;
