import React from "react";

function HardCodePhoto() {
	return (
		<React.Fragment>
			<div className="container">
				<div
					className="row mb-3 deleteHere justify-content-center"
					id="photosTemplate"
				>
					{/* <div id="CARDCOPY" className="card woodPeach font-body">
						<div className="card-header">
							<img
								className="card-img-top w-100 mb-3"
								id="photoUrl"
								src={photo.photoUrl}
								alt="Darnell Cole and The Vibes picture."
							/>
						</div>
						<div className="card-body text-left">
							<h4 className="card-location d-none" id="location">
								{photo.location}
							</h4>
							<h4 className="card-person d-none" id="person">
								{photo.person}
							</h4>
							<h4 className="fw-bolder">Photographer:</h4>
							<a
								href={photo.photographerUrl}
								class="card-link colorRed"
								target="_blank"
								rel="noopener noreferrer"
							>
								<h4 className="card-Photographer fw-bolder" id="Photographer">
									{photo.photographer}
								</h4>
							</a>
						</div>
					</div> */}

					{/* EVERYTIME PHOTOS ARE ADDED, MAKE SURE HARDCODEMEDIA'S 
                    PHOTOSTOTAL'S NUMBER IS THE SAME AS THE CURRENT ID="CARD#" */}

					{/* ADD NEW PICTURES UNDER THIS LINE. CONTINUE THE ID NUMBERS */}
					<div id="CARD28" className="card woodPeach font-body my-2">
						<div className="card-header">
							<img
								className="card-img-top w-100 mb-3"
								id="photoUrl"
								src="https://live.staticflickr.com/65535/52854449140_5a5966957a_z.jpg"
								alt="Darnell Cole and The Vibes."
							/>
						</div>
						<div className="card-body text-left">
							<h4 className="card-location d-none" id="location">
								House of Blues
							</h4>
							<h4 className="card-person d-none" id="person">
								Darnell
							</h4>
							<h4 className="fw-bolder">Photographer:</h4>
							<a
								href="https://www.instagram.com/photognicholas/"
								class="card-link colorRed"
								target="_blank"
								rel="noopener noreferrer"
							>
								<h4 className="card-Photographer fw-bolder" id="Photographer">
									Nicholas Sitarsk
								</h4>
							</a>
						</div>
					</div>
					<div id="CARD27" className="card woodPeach font-body my-2">
						<div className="card-header">
							<img
								className="card-img-top w-100 mb-3"
								id="photoUrl"
								src="https://live.staticflickr.com/65535/52854417780_5d4b22ba81_z.jpg"
								alt="Darnell Cole and The Vibes."
							/>
						</div>
						<div className="card-body text-left">
							<h4 className="card-location d-none" id="location">
								House of Blues
							</h4>
							<h4 className="card-person d-none" id="person">
								Darnell
							</h4>
							<h4 className="fw-bolder">Photographer:</h4>
							<a
								href="https://www.instagram.com/photognicholas/"
								class="card-link colorRed"
								target="_blank"
								rel="noopener noreferrer"
							>
								<h4 className="card-Photographer fw-bolder" id="Photographer">
									Nicholas Sitarsk
								</h4>
							</a>
						</div>
					</div>
					<div id="CARD26" className="card woodPeach font-body my-2">
						<div className="card-header">
							<img
								className="card-img-top w-100 mb-3"
								id="photoUrl"
								src="https://live.staticflickr.com/65535/52854471123_08626c9334_z.jpg"
								alt="Darnell Cole and The Vibes."
							/>
						</div>
						<div className="card-body text-left">
							<h4 className="card-location d-none" id="location">
								House of Blues
							</h4>
							<h4 className="card-person d-none" id="person">
								Darnell
							</h4>
							<h4 className="fw-bolder">Photographer:</h4>
							<a
								href="https://www.instagram.com/photognicholas/"
								class="card-link colorRed"
								target="_blank"
								rel="noopener noreferrer"
							>
								<h4 className="card-Photographer fw-bolder" id="Photographer">
									Nicholas Sitarsk
								</h4>
							</a>
						</div>
					</div>
					<div id="CARD25" className="card woodPeach font-body my-2">
						<div className="card-header">
							<img
								className="card-img-top w-100 mb-3"
								id="photoUrl"
								src="https://live.staticflickr.com/65535/52854470348_11af0d8acf_z.jpg"
								alt="Darnell Cole and The Vibes."
							/>
						</div>
						<div className="card-body text-left">
							<h4 className="card-location d-none" id="location">
								House of Blues
							</h4>
							<h4 className="card-person d-none" id="person">
								Darnell
							</h4>
							<h4 className="fw-bolder">Photographer:</h4>
							<a
								href="https://www.instagram.com/photognicholas/"
								class="card-link colorRed"
								target="_blank"
								rel="noopener noreferrer"
							>
								<h4 className="card-Photographer fw-bolder" id="Photographer">
									Nicholas Sitarsk
								</h4>
							</a>
						</div>
					</div>
					<div id="CARD24" className="card woodPeach font-body my-2">
						<div className="card-header">
							<img
								className="card-img-top w-100 mb-3"
								id="photoUrl"
								src="https://live.staticflickr.com/65535/52854023421_fd470839d5_w.jpg"
								alt="Darnell Cole and The Vibes."
							/>
						</div>
						<div className="card-body text-left">
							<h4 className="card-location d-none" id="location">
								House of Blues
							</h4>
							<h4 className="card-person d-none" id="person">
								Darnell
							</h4>
							<h4 className="fw-bolder">Photographer:</h4>
							<a
								href="https://www.instagram.com/photognicholas/"
								class="card-link colorRed"
								target="_blank"
								rel="noopener noreferrer"
							>
								<h4 className="card-Photographer fw-bolder" id="Photographer">
									Nicholas Sitarsk
								</h4>
							</a>
						</div>
					</div>
					<div id="CARD23" className="card woodPeach font-body my-2">
						<div className="card-header">
							<img
								className="card-img-top w-100 mb-3"
								id="photoUrl"
								src="https://live.staticflickr.com/65535/52854471093_6d2fc99d51_w.jpg"
								alt="Darnell Cole and The Vibes."
							/>
						</div>
						<div className="card-body text-left">
							<h4 className="card-location d-none" id="location">
								House of Blues
							</h4>
							<h4 className="card-person d-none" id="person">
								Darnell
							</h4>
							<h4 className="fw-bolder">Photographer:</h4>
							<a
								href="https://www.instagram.com/photognicholas/"
								class="card-link colorRed"
								target="_blank"
								rel="noopener noreferrer"
							>
								<h4 className="card-Photographer fw-bolder" id="Photographer">
									Nicholas Sitarsk
								</h4>
							</a>
						</div>
					</div>
					<div id="CARD22" className="card woodPeach font-body my-2">
						<div className="card-header">
							<img
								className="card-img-top w-100 mb-3"
								id="photoUrl"
								src="https://live.staticflickr.com/65535/52853446302_e1ffb577bc.jpg"
								alt="Darnell Cole and The Vibes."
							/>
						</div>
						<div className="card-body text-left">
							<h4 className="card-location d-none" id="location">
								House of Blues
							</h4>
							<h4 className="card-person d-none" id="person">
								Darnell
							</h4>
							<h4 className="fw-bolder">Photographer:</h4>
							<a
								href="https://www.instagram.com/photognicholas/"
								class="card-link colorRed"
								target="_blank"
								rel="noopener noreferrer"
							>
								<h4 className="card-Photographer fw-bolder" id="Photographer">
									Nicholas Sitarsk
								</h4>
							</a>
						</div>
					</div>
					<div id="CARD21" className="card woodPeach font-body my-2">
						<div className="card-header">
							<img
								className="card-img-top w-100 mb-3"
								id="photoUrl"
								src="https://live.staticflickr.com/65535/52854202924_4f9a6f597f_z.jpg"
								alt="Darnell Cole and The Vibes."
							/>
						</div>
						<div className="card-body text-left">
							<h4 className="card-location d-none" id="location">
								House of Blues
							</h4>
							<h4 className="card-person d-none" id="person">
								Darnell
							</h4>
							<h4 className="fw-bolder">Photographer:</h4>
							<a
								href="https://www.instagram.com/photognicholas/"
								class="card-link colorRed"
								target="_blank"
								rel="noopener noreferrer"
							>
								<h4 className="card-Photographer fw-bolder" id="Photographer">
									Nicholas Sitarsk
								</h4>
							</a>
						</div>
					</div>
					<div id="CARD20" className="card woodPeach font-body my-2">
						<div className="card-header">
							<img
								className="card-img-top w-100 mb-3"
								id="photoUrl"
								src="https://live.staticflickr.com/65535/52853445717_d760fbbb0a_w.jpg"
								alt="Darnell Cole and The Vibes."
							/>
						</div>
						<div className="card-body text-left">
							<h4 className="card-location d-none" id="location">
								House of Blues
							</h4>
							<h4 className="card-person d-none" id="person">
								Darnell
							</h4>
							<h4 className="fw-bolder">Photographer:</h4>
							<a
								href="https://www.instagram.com/photognicholas/"
								class="card-link colorRed"
								target="_blank"
								rel="noopener noreferrer"
							>
								<h4 className="card-Photographer fw-bolder" id="Photographer">
									Nicholas Sitarsk
								</h4>
							</a>
						</div>
					</div>
					<div id="CARD19" className="card woodPeach font-body my-2">
						<div className="card-header">
							<img
								className="card-img-top w-100 mb-3"
								id="photoUrl"
								src="https://live.staticflickr.com/65535/52854025006_a7240e052d_w.jpg"
								alt="Darnell Cole and The Vibes."
							/>
						</div>
						<div className="card-body text-left">
							<h4 className="card-location d-none" id="location">
								House of Blues
							</h4>
							<h4 className="card-person d-none" id="person">
								Darnell
							</h4>
							<h4 className="fw-bolder">Photographer:</h4>
							<a
								href="https://www.instagram.com/photognicholas/"
								class="card-link colorRed"
								target="_blank"
								rel="noopener noreferrer"
							>
								<h4 className="card-Photographer fw-bolder" id="Photographer">
									Nicholas Sitarsk
								</h4>
							</a>
						</div>
					</div>
					<div id="CARD18" className="card woodPeach font-body my-2">
						<div className="card-header">
							<img
								className="card-img-top w-100 mb-3"
								id="photoUrl"
								src="https://live.staticflickr.com/65535/52854025846_eba9b73bb1_z.jpg"
								alt="Darnell Cole and The Vibes."
							/>
						</div>
						<div className="card-body text-left">
							<h4 className="card-location d-none" id="location">
								House of Blues
							</h4>
							<h4 className="card-person d-none" id="person">
								Darnell
							</h4>
							<h4 className="fw-bolder">Photographer:</h4>
							<a
								href="https://www.instagram.com/photognicholas/"
								class="card-link colorRed"
								target="_blank"
								rel="noopener noreferrer"
							>
								<h4 className="card-Photographer fw-bolder" id="Photographer">
									Nicholas Sitarsk
								</h4>
							</a>
						</div>
					</div>
					<div id="CARD17" className="card woodPeach font-body my-2">
						<div className="card-header">
							<img
								className="card-img-top w-100 mb-3"
								id="photoUrl"
								src="https://live.staticflickr.com/65535/52854473633_222cec9e0e_z.jpg"
								alt="Darnell Cole and The Vibes."
							/>
						</div>
						<div className="card-body text-left">
							<h4 className="card-location d-none" id="location">
								House of Blues
							</h4>
							<h4 className="card-person d-none" id="person">
								Darnell
							</h4>
							<h4 className="fw-bolder">Photographer:</h4>
							<a
								href="https://www.instagram.com/photognicholas/"
								class="card-link colorRed"
								target="_blank"
								rel="noopener noreferrer"
							>
								<h4 className="card-Photographer fw-bolder" id="Photographer">
									Nicholas Sitarsk
								</h4>
							</a>
						</div>
					</div>
					<div id="CARD16" className="card woodPeach font-body my-2">
						<div className="card-header">
							<img
								className="card-img-top w-100 mb-3"
								id="photoUrl"
								src="https://live.staticflickr.com/65535/52854420775_d83e80cefd_z.jpg"
								alt="Darnell Cole and The Vibes."
							/>
						</div>
						<div className="card-body text-left">
							<h4 className="card-location d-none" id="location">
								House of Blues
							</h4>
							<h4 className="card-person d-none" id="person">
								Darnell
							</h4>
							<h4 className="fw-bolder">Photographer:</h4>
							<a
								href="https://www.instagram.com/photognicholas/"
								class="card-link colorRed"
								target="_blank"
								rel="noopener noreferrer"
							>
								<h4 className="card-Photographer fw-bolder" id="Photographer">
									Nicholas Sitarsk
								</h4>
							</a>
						</div>
					</div>
					<div id="CARD15" className="card woodPeach font-body my-2">
						<div className="card-header">
							<img
								className="card-img-top w-100 mb-3"
								id="photoUrl"
								src="https://live.staticflickr.com/65535/52854421050_3517fd10ea_z.jpg"
								alt="Darnell Cole and The Vibes."
							/>
						</div>
						<div className="card-body text-left">
							<h4 className="card-location d-none" id="location">
								House of Blues
							</h4>
							<h4 className="card-person d-none" id="person">
								Darnell
							</h4>
							<h4 className="fw-bolder">Photographer:</h4>
							<a
								href="https://www.instagram.com/photognicholas/"
								class="card-link colorRed"
								target="_blank"
								rel="noopener noreferrer"
							>
								<h4 className="card-Photographer fw-bolder" id="Photographer">
									Nicholas Sitarsk
								</h4>
							</a>
						</div>
					</div>
					<div id="CARD14" className="card woodPeach font-body my-2">
						<div className="card-header">
							<img
								className="card-img-top w-100 mb-3"
								id="photoUrl"
								src="https://live.staticflickr.com/65535/52853447092_540219353d.jpg"
								alt="Darnell Cole and The Vibes."
							/>
						</div>
						<div className="card-body text-left">
							<h4 className="card-location d-none" id="location">
								House of Blues
							</h4>
							<h4 className="card-person d-none" id="person">
								Darnell
							</h4>
							<h4 className="fw-bolder">Photographer:</h4>
							<a
								href="https://www.instagram.com/photognicholas/"
								class="card-link colorRed"
								target="_blank"
								rel="noopener noreferrer"
							>
								<h4 className="card-Photographer fw-bolder" id="Photographer">
									Nicholas Sitarsk
								</h4>
							</a>
						</div>
					</div>
					<div id="CARD13" className="card woodPeach font-body my-2">
						<div className="card-header">
							<img
								className="card-img-top w-100 mb-3"
								id="photoUrl"
								src="https://live.staticflickr.com/65535/52854474343_e2cc4ae7f3_n.jpg"
								alt="Darnell Cole and The Vibes."
							/>
						</div>
						<div className="card-body text-left">
							<h4 className="card-location d-none" id="location">
								House of Blues
							</h4>
							<h4 className="card-person d-none" id="person">
								Darnell
							</h4>
							<h4 className="fw-bolder">Photographer:</h4>
							<a
								href="https://www.instagram.com/photognicholas/"
								class="card-link colorRed"
								target="_blank"
								rel="noopener noreferrer"
							>
								<h4 className="card-Photographer fw-bolder" id="Photographer">
									Nicholas Sitarsk
								</h4>
							</a>
						</div>
					</div>
					<div id="CARD12" className="card woodPeach font-body my-2">
						<div className="card-header">
							<img
								className="card-img-top w-100 mb-3"
								id="photoUrl"
								src="https://live.staticflickr.com/65535/52421591188_36d4c0bf8b_c.jpg"
								alt="Darnell Cole and The Vibes."
							/>
						</div>
						<div className="card-body text-left">
							<h4 className="card-location d-none" id="location">
								Chain Reaction
							</h4>
							<h4 className="card-person d-none" id="person">
								Darnell
							</h4>
							<h4 className="fw-bolder">Photographer:</h4>
							<a
								href="https://www.instagram.com/jeromebrunet/"
								class="card-link colorRed"
								target="_blank"
								rel="noopener noreferrer"
							>
								<h4 className="card-Photographer fw-bolder" id="Photographer">
									Jerome Brunet
								</h4>
							</a>
						</div>
					</div>
					<div id="CARD11" className="card woodPeach font-body my-2">
						<div className="card-header">
							<img
								className="card-img-top w-100 mb-3"
								id="photoUrl"
								src="https://live.staticflickr.com/65535/52421078161_49c6bcf396.jpg"
								alt="Darnell Cole and The Vibes."
							/>
						</div>
						<div className="card-body text-left">
							<h4 className="card-location d-none" id="location">
								Chain Reaction
							</h4>
							<h4 className="card-person d-none" id="person">
								Darnell
							</h4>
							<h4 className="fw-bolder">Photographer:</h4>
							<a
								href="https://www.instagram.com/jeromebrunet/"
								class="card-link colorRed"
								target="_blank"
								rel="noopener noreferrer"
							>
								<h4 className="card-Photographer fw-bolder" id="Photographer">
									Jerome Brunet
								</h4>
							</a>
						</div>
					</div>
					<div id="CARD10" className="card woodPeach font-body my-2">
						<div className="card-header">
							<img
								className="card-img-top w-100 mb-3"
								id="photoUrl"
								src="https://live.staticflickr.com/65535/52421362274_06758cac67.jpg"
								alt="Darnell Cole and The Vibes."
							/>
						</div>
						<div className="card-body text-left">
							<h4 className="card-location d-none" id="location">
								Chain Reaction
							</h4>
							<h4 className="card-person d-none" id="person">
								Darnell
							</h4>
							<h4 className="fw-bolder">Photographer:</h4>
							<a
								href="https://www.instagram.com/jeromebrunet/"
								class="card-link colorRed"
								target="_blank"
								rel="noopener noreferrer"
							>
								<h4 className="card-Photographer fw-bolder" id="Photographer">
									Jerome Brunet
								</h4>
							</a>
						</div>
					</div>
					<div id="CARD9" className="card woodPeach font-body my-2">
						<div className="card-header">
							<img
								className="card-img-top w-100 mb-3"
								id="photoUrl"
								src="https://live.staticflickr.com/65535/52420555672_e07dda4226_b.jpg"
								alt="Darnell Cole and The Vibes."
							/>
						</div>
						<div className="card-body text-left">
							<h4 className="card-location d-none" id="location">
								Rail Ale Bluesfest
							</h4>
							<h4 className="card-person d-none" id="person">
								Darnell, Band
							</h4>
							<h4 className="fw-bolder">Photographer:</h4>
							<a
								href="https://www.instagram.com/hughtom.live/"
								class="card-link colorRed"
								target="_blank"
								rel="noopener noreferrer"
							>
								<h4 className="card-Photographer fw-bolder" id="Photographer">
									Hugh Tom
								</h4>
							</a>
						</div>
					</div>
					<div id="CARD8" className="card woodPeach font-body my-2">
						<div className="card-header">
							<img
								className="card-img-top w-100 mb-3"
								id="photoUrl"
								src="https://live.staticflickr.com/65535/52421352774_4ded0a1d5f.jpg"
								alt="Darnell Cole and The Vibes."
							/>
						</div>
						<div className="card-body text-left">
							<h4 className="card-location d-none" id="location">
								Rail Ale Bluesfest
							</h4>
							<h4 className="card-person d-none" id="person">
								Darnell
							</h4>
							<h4 className="fw-bolder">Photographer:</h4>
							<a
								href="https://www.instagram.com/hughtom.live/"
								class="card-link colorRed"
								target="_blank"
								rel="noopener noreferrer"
							>
								<h4 className="card-Photographer fw-bolder" id="Photographer">
									Hugh Tom
								</h4>
							</a>
						</div>
					</div>
					<div id="CARD7" className="card woodPeach font-body my-2">
						<div className="card-header">
							<img
								className="card-img-top w-100 mb-3"
								id="photoUrl"
								src="https://live.staticflickr.com/65535/52421513055_6b099948a0_b.jpg"
								alt="Darnell Cole and The Vibes."
							/>
						</div>
						<div className="card-body text-left">
							<h4 className="card-location d-none" id="location">
								Rail Ale Bluesfest
							</h4>
							<h4 className="card-person d-none" id="person">
								Darnell, Fans
							</h4>
							<h4 className="fw-bolder">Photographer:</h4>
							<a
								href="https://www.instagram.com/hughtom.live/"
								class="card-link colorRed"
								target="_blank"
								rel="noopener noreferrer"
							>
								<h4 className="card-Photographer fw-bolder" id="Photographer">
									Hugh Tom
								</h4>
							</a>
						</div>
					</div>
					<div id="CARD6" className="card woodPeach font-body my-2">
						<div className="card-header">
							<img
								className="card-img-top w-100 mb-3"
								id="photoUrl"
								src="https://live.staticflickr.com/65535/52421061286_53bd464491.jpg"
								alt="Darnell Cole and The Vibes."
							/>
						</div>
						<div className="card-body text-left">
							<h4 className="card-location d-none" id="location">
								Waterloo
							</h4>
							<h4 className="card-person d-none" id="person">
								Darnell
							</h4>
							<h4 className="fw-bolder">Photographer:</h4>
							<a
								href="https://www.instagram.com/louisedoesgigs/"
								class="card-link colorRed"
								target="_blank"
								rel="noopener noreferrer"
							>
								<h4 className="card-Photographer fw-bolder" id="Photographer">
									Louise Newiss
								</h4>
							</a>
						</div>
					</div>
					<div id="CARD5" className="card woodPeach font-body my-2">
						<div className="card-header">
							<img
								className="card-img-top w-100 mb-3"
								id="photoUrl"
								src="https://live.staticflickr.com/65535/52420548292_87be3a2c2b_c.jpg"
								alt="Darnell Cole and The Vibes."
							/>
						</div>
						<div className="card-body text-left">
							<h4 className="card-location d-none" id="location">
								Waterloo
							</h4>
							<h4 className="card-person d-none" id="person">
								Darnell
							</h4>
							<h4 className="fw-bolder">Photographer:</h4>
							<a
								href="https://www.instagram.com/louisedoesgigs/"
								class="card-link colorRed"
								target="_blank"
								rel="noopener noreferrer"
							>
								<h4 className="card-Photographer fw-bolder" id="Photographer">
									Louise Newiss
								</h4>
							</a>
						</div>
					</div>
					<div id="CARD4" className="card woodPeach font-body my-2">
						<div className="card-header">
							<img
								className="card-img-top w-100 mb-3"
								id="photoUrl"
								src="https://live.staticflickr.com/65535/52421573818_2eb75920de_c.jpg"
								alt="Darnell Cole and The Vibes."
							/>
						</div>
						<div className="card-body text-left">
							<h4 className="card-location d-none" id="location">
								Waterloo
							</h4>
							<h4 className="card-person d-none" id="person">
								Darnell, Band
							</h4>
							<h4 className="fw-bolder">Photographer:</h4>
							<a
								href="https://www.instagram.com/louisedoesgigs/"
								class="card-link colorRed"
								target="_blank"
								rel="noopener noreferrer"
							>
								<h4 className="card-Photographer fw-bolder" id="Photographer">
									Louise Newiss
								</h4>
							</a>
						</div>
					</div>
					<div id="CARD3" className="card woodPeach font-body my-2">
						<div className="card-header">
							<img
								className="card-img-top w-100 mb-3"
								id="photoUrl"
								src="https://live.staticflickr.com/65535/52420543097_203ebb8c2b_c.jpg"
								alt="Darnell Cole and The Vibes."
							/>
						</div>
						<div className="card-body text-left">
							<h4 className="card-location d-none" id="location">
								Tuesday NIght Music Club
							</h4>
							<h4 className="card-person d-none" id="person">
								Darnell, Band
							</h4>
							<h4 className="fw-bolder">Photographer:</h4>
							<a
								href="https://www.instagram.com/kenjackson2629/"
								class="card-link colorRed"
								target="_blank"
								rel="noopener noreferrer"
							>
								<h4 className="card-Photographer fw-bolder" id="Photographer">
									Ken Jackson
								</h4>
							</a>
						</div>
					</div>
					<div id="CARD2" className="card woodPeach font-body my-2">
						<div className="card-header">
							<img
								className="card-img-top w-100 mb-3"
								id="photoUrl"
								src="https://live.staticflickr.com/65535/52421056201_c3ccbc5c1a_c.jpg"
								alt="Darnell Cole and The Vibes."
							/>
						</div>
						<div className="card-body text-left">
							<h4 className="card-location d-none" id="location">
								Tuesday NIght Music Club
							</h4>
							<h4 className="card-person d-none" id="person">
								Darnell
							</h4>
							<h4 className="fw-bolder">Photographer:</h4>
							<a
								href="https://www.instagram.com/kenjackson2629/"
								class="card-link colorRed"
								target="_blank"
								rel="noopener noreferrer"
							>
								<h4 className="card-Photographer fw-bolder" id="Photographer">
									Ken Jackson
								</h4>
							</a>
						</div>
					</div>
					<div id="CARD1" className="card woodPeach font-body my-2">
						<div className="card-header">
							<img
								className="card-img-top w-100 mb-3"
								id="photoUrl"
								src="https://live.staticflickr.com/65535/52421340844_abc1d3b54b_c.jpg"
								alt="Darnell Cole and The Vibes."
							/>
						</div>
						<div className="card-body text-left">
							<h4 className="card-location d-none" id="location">
								Tuesday NIght Music Club
							</h4>
							<h4 className="card-person d-none" id="person">
								Darnell
							</h4>
							<h4 className="fw-bolder">Photographer:</h4>
							<a
								href="https://www.instagram.com/kenjackson2629/"
								class="card-link colorRed"
								target="_blank"
								rel="noopener noreferrer"
							>
								<h4 className="card-Photographer fw-bolder" id="Photographer">
									Ken Jackson
								</h4>
							</a>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default HardCodePhoto;
