import React from "react";

function HardCodeVideo() {
	return (
		<React.Fragment>
			<div className="container">
				<div
					className="row mb-3 deleteHere justify-content-center"
					id="videosTemplate"
				>
					{/* <div id="CARDCOPY" className="card woodTif fw-bolder font-body">
						<div className="card-header pt-3 text-center">
							<h4 className="fw-bolder">{video.song}</h4>
							<h4 className="d-none">{video.performance}</h4>
							<h4 className="d-none">{video.location}</h4>
						</div>
						<div className="video-container">
							<iframe
								className="video py-2"
								title="videoMapped"
								allowfullscreen
								src={video.videoUrl}
							></iframe>
						</div>
					</div> */}

					{/* EVERYTIME VIDEOS ARE ADDED, MAKE SURE HARDCODEMEDIA'S 
                    VIDEOSTOTAL'S NUMBER IS THE SAME AS THE CURRENT ID="CARD#" */}

					{/* ADD NEW VIDEO UNDER THIS LINE. CONTINUE THE ID NUMBERS */}
					
					<div id="CARD9" className="card woodTif fw-bolder font-body my-2">
						<div className="card-header pt-3 text-center">
							<h4 className="fw-bolder">Take Me By The Hand</h4>
							<h4 className="d-none">Official Music Video</h4>
							<h4 className="d-none">Official Music Video</h4>
						</div>
						<div className="video-container">
							<iframe
								className="video py-2"
								title="videoMapped"
								allowfullscreen
								src="https://www.youtube.com/embed/9EhCN9UYuZY?list=OLAK5uy_mRdAiZBhL8OtVRwRKz8nqYqOFhmG0Xqqk"
							></iframe>
						</div>
					</div>				
					<div id="CARD8" className="card woodTif fw-bolder font-body my-2">
						<div className="card-header pt-3 text-center">
							<h4 className="fw-bolder">Gypsy Soul</h4>
							<h4 className="d-none">Official Music Video</h4>
							<h4 className="d-none">Official Music Video</h4>
						</div>
						<div className="video-container">
							<iframe
								className="video py-2"
								title="videoMapped"
								allowfullscreen
								src="https://www.youtube.com/embed/sIi8bPQfUhk"
							></iframe>
						</div>
					</div>
					<div id="CARD7" className="card woodTif fw-bolder font-body my-2">
						<div className="card-header pt-3 text-center">
							<h4 className="fw-bolder">On My Own</h4>
							<h4 className="d-none">Official Music Video</h4>
							<h4 className="d-none">Official Music Video</h4>
						</div>
						<div className="video-container">
							<iframe
								className="video py-2"
								title="videoMapped"
								allowfullscreen
								src="https://www.youtube.com/embed/WhABWaqluiE"
							></iframe>
						</div>
					</div>
					<div id="CARD6" className="card woodTif fw-bolder font-body my-2">
						<div className="card-header pt-3 text-center">
							<h4 className="fw-bolder">Sleeping Beauties</h4>
							<h4 className="d-none">Live</h4>
							<h4 className="d-none">Church House. Sheffield UK</h4>
						</div>
						<div className="video-container">
							<iframe
								className="video py-2"
								title="videoMapped"
								allowfullscreen
								src="https://www.youtube.com/embed/56Rv_DyqY9Q"
							></iframe>
						</div>
					</div>
					<div id="CARD5" className="card woodTif fw-bolder font-body my-2">
						<div className="card-header pt-3 text-center">
							<h4 className="fw-bolder">Warm Days Hot Nights</h4>
							<h4 className="d-none">Live</h4>
							<h4 className="d-none">Church House. Sheffield UK</h4>
						</div>
						<div className="video-container">
							<iframe
								className="video py-2"
								title="videoMapped"
								allowfullscreen
								src="https://www.youtube.com/embed/9dpXtMxl31k"
							></iframe>
						</div>
					</div>
					<div id="CARD4" className="card woodTif fw-bolder font-body my-2">
						<div className="card-header pt-3 text-center">
							<h4 className="fw-bolder">Songbird</h4>
							<h4 className="d-none">Live</h4>
							<h4 className="d-none">Tuesday NIght Music Club, Coulsdon UK</h4>
						</div>
						<div className="video-container">
							<iframe
								className="video py-2"
								title="videoMapped"
								allowfullscreen
								src="https://www.youtube.com/embed/ek5JLg4p2Lc"
							></iframe>
						</div>
					</div>
					<div id="CARD3" className="card woodTif fw-bolder font-body my-2">
						<div className="card-header pt-3 text-center">
							<h4 className="fw-bolder">Hold On For Tomorrow</h4>
							<h4 className="d-none">Live</h4>
							<h4 className="d-none">Tuesday NIght Music Club, Coulsdon UK</h4>
						</div>
						<div className="video-container">
							<iframe
								className="video py-2"
								title="videoMapped"
								allowfullscreen
								src="https://www.youtube.com/embed/EtO7hXZqqtw"
							></iframe>
						</div>
					</div>
					<div id="CARD2" className="card woodTif fw-bolder font-body my-2">
						<div className="card-header pt-3 text-center">
							<h4 className="fw-bolder">Make Love</h4>
							<h4 className="d-none">Live</h4>
							<h4 className="d-none">Tuesday NIght Music Club, Coulsdon UK</h4>
						</div>
						<div className="video-container">
							<iframe
								className="video py-2"
								title="videoMapped"
								allowfullscreen
								src="https://www.youtube.com/embed/-AMyQjok4bY"
							></iframe>
						</div>
					</div>
					<div id="CARD1" className="card woodTif fw-bolder font-body my-2">
						<div className="card-header pt-3 text-center">
							<h4 className="fw-bolder">On My Own</h4>
							<h4 className="d-none">Live</h4>
							<h4 className="d-none">
								South Durham Social / Steelies, Hartlepool UK
							</h4>
						</div>
						<div className="video-container">
							<iframe
								className="video py-2"
								title="videoMapped"
								allowfullscreen
								src="https://www.youtube.com/embed/QGZroVvh0Pg"
							></iframe>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default HardCodeVideo;
