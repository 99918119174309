import React from "react";
import SongKickWidget from "../events/SongKickWidget";
import flyer from "../../images/TourFlyer2024.jpg";

function Events() {
	return (
		<React.Fragment>
			<div className="container-fluid font-body app-bg">
				<div className="row my-5 text-left">
					<div className="col text-center">
						<h1 className="font-title colorWhite fw-bolder mt-2">
							{" "}
							TOUR DATES
						</h1>
						<div>
							<img
								id="tourFlyer"
								src={flyer}
								alt="Blank Ticket"
								className="tourFlyer"
							/>
							{/* <h4 className="colorWhite font-title">
								Stay posted! A new tour is coming!
							</h4> */}
						</div>
					</div>
				</div>
				<div className="row mb-5 text-left">
					<div className="col text-center">
						<h1 className="font-title colorWhite fw-bolder">SHOW DATES</h1>
						<div className="row p-4 woodTif">
							<div className="col mx-2 sectionPeach">
								<SongKickWidget />
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default Events;
